.App {
  text-align: center;
}

.App-header {
  background-color: #282C34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFFFFF;
}

.subbar-text {
  color: #DDDDDD;
  font-weight: bolder;
  font-size: 0.85em;
}

body {
  background-color: #F4F4F4;
}

.owl-nav-bar {
  background-color: #1A90CF;
  height: 64px;
}

.owl-subnav-bar {
  background-color: #000000;
  height: 44px;
}

.owl-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 0.75em;
  color: #888888;
}

.formLabel {
  padding-top: 30px;
  font-size: 1.1em;
  font-weight: 600;
}

.formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 2px;
  background: #FFFFFF;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 10px;
  margin-top: 100px;
  -webkit-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
}

.pinErrorText {
  color: #DD0000;
  font-size: 0.9em;
}

.fitimage {
  width: auto;
  width: 320px;
  height: auto;
  height: 240px;
}

input[type=button],
input[type=submit],
input[type=reset],
.owl-button {
  background-color: #1A90CF;
  border: none;
  color: #FFFFFF;
  padding: 15px 75px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 10px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

input[type=text] {
  padding: 8px;
  border: 1px;
  border-style: solid;
  border-color: #DDDDDD;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 10px 20px 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

/**************************************************
**** These styles are specifically overriding *****
**** default bootstrap styles that were being *****
* relied on, but no longer match the color scheme *
**************************************************/
.btn-success.focus,
.btn-success:focus{
  box-shadow: 0 0 0 0.2rem rgba(13, 99, 145, 0.5) !important;
}

.btn-success:not(:disabled):not(.disabled).active, 
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #0D6391;
  border-color: #0D6391;
}

input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover,
.owl-button:hover {
  background-color: #0D6391;
}
